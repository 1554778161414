<template>
  <div
    class="slide-element-mobile rounded-lg"
    :style="`background-image: url(${slide.src})`"
  >
    <div class="img-title text-h2 text-uppercase py-4">
      {{ t("home.heroSlider.titleMobile") }}
    </div>
    <div class="img-wrapper rounded-lg overflow-hidden px-3 pb-3">
      <img
        :src="slide.src"
        :alt="slide.altText"
        class="img-current rounded-lg"
        style="width: 100%; height: 100%"
      />
    </div>
    <div class="grid-container mx-4" style="max-width: 100%">
      <div class="text-wrapper">
        <div class="slide-title text-h2 pt-2 pb-4 grid-area-title">
          {{ slide.title }}
        </div>
        <div class="slide-text grid-area-text" v-html="slide.text"></div>
      </div>
      <navigation-button
        class="mt-lg-8 ml-auto nav-button mr-6 grid-area-button pt-4"
        v-bind="getButtonProps(slide)"
        :tabindex="!activeSlide ? -1 : 0"
        style="--v-theme-primary: 255, 255, 255"
      >
        {{ slide.buttonText }}
      </navigation-button>
      <div
        class="controls-container slider-controls--mobile grid-area-controls justify-center"
      >
        <SliderControls
          button-color="#ffffff"
          :isPaused="isPaused"
          :isSliderHovered="isSliderHovered"
          :activeButton="activeButton"
          :onNext="onNext"
          :onPrevious="onPrevious"
          :onToggle="onToggle"
          :ariaLabel="t('home.heroSlider.aria.carouselControls')"
          :ariaLabelPrev="t('home.heroSlider.aria.previousSlide')"
          :ariaLabelNext="t('home.heroSlider.aria.nextSlide')"
          :ariaLabelToggle="
            isPaused
              ? t('home.heroSlider.aria.playCarousel')
              : t('home.heroSlider.aria.pauseCarousel')
          "
          class="mr-2"
        />
        <ProgressBar
          class="ml-2"
          :value="progressBarValue"
          aria-hidden="true"
          :ariaLabel="
            t('home.heroSlider.aria.progressBar', {
              value: progressBarValue,
            })
          "
          style="--progress-bar-color: 255, 255, 255"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import SliderControls from "./SliderControls.vue";
import ProgressBar from "./ProgressBar.vue";

interface Slide {
  src: string;
  altText: string;
  title: string;
  text: string;
  buttonText: string;
}

interface Props {
  slide: Slide;
  getButtonProps: (slide: Slide) => Record<string, any>;
  isPaused: boolean;
  isSliderHovered: boolean;
  activeButton: string;
  onNext: () => void;
  onPrevious: () => void;
  onToggle: () => void;
  progressBarValue: number;
  activeSlide?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  activeSlide: true,
});
const { t } = useI18n();
</script>

<style lang="scss">
.slide-element-mobile {
  //background: transparent;
  background-color: black;
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -40px;
    left: -40px;
    right: -40px;
    bottom: -40px;
    background-image: inherit;
    background-position: center;
    background-size: cover;
    filter: blur(20px) brightness(0.3);
    z-index: 0;
  }

  .grid-container {
    display: grid;
    grid-template-areas:
      "title"
      "text"
      "button"
      "controls";
    grid-template-rows: auto 1fr auto auto;
    height: calc(
      100% - 310px
    ); // considers the height of img-title and img-wrapper
    position: relative;
    z-index: 1;

    .text-wrapper {
      min-height: 160px; // considers the height of img-title and img-wrapper
    }
  }

  .grid-area-title {
    grid-area: title;
    max-height: 100px;
    overflow: hidden;
  }

  .grid-area-text {
    grid-area: text;
    overflow-y: auto;
  }

  .grid-area-button {
    grid-area: button;
  }

  .grid-area-controls {
    grid-area: controls;
    padding-inline: 8px;
  }

  .img-title,
  .img-wrapper,
  .slide-title,
  .slide-text,
  .nav-button {
    position: relative;
    z-index: 1;
    color: white;
  }

  .controls-container {
    z-index: 10;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
  }

  .progress-bar {
    width: 100%;
  }

  .slider-controls--mobile {
    margin-top: auto;
    padding-bottom: 10px;
  }

  .slide-title,
  .slide-text {
    transform: translateZ(0);
    backface-visibility: hidden;
    will-change: transform;
  }
}
</style>
